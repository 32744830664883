import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import {
  Text,
  TextPic,
  Pic,
  LoeweWrapper,
} from '../components/TextComponents/styled';
import ScrollAnimation from '../animation/scrollAnimation';
import '../animation/animation';
import '../animation/animate.css';

export default function AboutPage({ data, location }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Über uns"
      description="Informationen über den Gau Alt-Burgund"
      location={location}
    >
      {/* Stories */}
      <TextPic>
        <Text>
          <h2>Der Gau Alt-Burgund</h2>
          <p>
            Für jeden, der uns nicht kennt, wollen wir uns hier kurz vorstellen.
            Ein Gau verbindet mehrerer Stämme und Siedlungen, also Ortsgruppen
            miteinander. Im Gau Alt-Burgund beispielsweise arbeiten die
            Pfadfinder*innen von Mainz über Nieder-Olm und Wörrstadt bis Worms
            und in die Pfalz miteinander. In diesem Rahmen veranstalten wir
            gemeinsame Aktionen, tauschen uns aus und unterstützen uns
            gegenseitig, wenn notwendig. Beispielsweise haben wir im Gau ein
            sehr umfassendes Schulungsprogramm für unsere neuen Gruppenführer
            über mehrere Wochenenden zusammengestellt.
          </p>
          <p>
            In RPS (also Rheinland-Pfalz und Saarland) gibt es insgesamt 8 Gaue
            und einen Bezirk (unterscheidet sich im Prinzip nur durch den
            Namen).
          </p>
          <p>
            Auf Gau-Ebene haben wir verschiedene Ämter: Die Gauführer*in
            sorgt dafür, dass die Interessen der einzelnen Stämme im Land
            vertreten werden und hält die Kommunikation zwischen den
            Stammesführer*innen aufrecht. Im Gau gibt es natürlich noch andere
            wichtige Aufgaben, wie beispielsweise die Wölflingsbeauftragte.
            Sie sorgt dafür, dass regelmäßig Veranstaltungen stattfinden,
            an denen auch unsere jüngsten Mitglieder die Pfadfinder*innen aus
            anderen Städten kennen lernen.
          </p>
        </Text>
        <Pic imageWidth={160}>
          <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
            <LoeweWrapper>
              {/* eslint-disable-next-line */}
              <Img fluid={data.loewe.childImageSharp.fluid} />
            </LoeweWrapper>
          </ScrollAnimation>
          <h3>Unser Gauwappen</h3>
        </Pic>
      </TextPic>

      <TextPic>
        <Text>
          <h2>Geschichte</h2>
          <p>
            In den Jahren nach 1922 entstanden die ersten Gruppen von
            Christlichen Pfadfindern im Gebiet des heutigen Gaues Alt-Burgund.
            Zu ihnen gehörte auch der Stamm Nibelungen in Worms. Aber bereits
            1933 wurde das Wachstum der Pfadfinderbewegung abgebrochen, die
            Jungengruppen wurden aufgelöst oder in die Hitlerjugend überführt,
            nur die Älteren über 18 Jahren blieben in der Christlichen
            Pfadfinderschaft Deutschlands (CPD).
          </p>
          <p>
            Nach dem Zweiten Weltkrieg wurde an vielen Orten erneut mit der
            Pfadfinderarbeit begonnen, meist durch alte Pfadfinder und fast
            immer unter anderem Namen, da der Begriff Pfadfinder verboten war.
            Dies änderte sich erst 1949, als die ersten Stämme von der
            französischen Militärregierung genehmigt wurden. Jetzt war es viel
            einfacher, neue Gruppen zu gründen. Durch dieses starke Anwachsen
            der CPD wurden aber auch Strukturen notwendig, in denen man
            zusammenarbeiten konnte. Dafür entstanden Gaue, manche wurden wieder
            aufgelöst, zusammengeschlossen und erneut geteilt. In diese Phase
            fällt auch die Gründung des Gaues Alt-Burgund am 27. September 1953.
            Zu dieser Zeit hatte der Gau in Hermann Haaß, der als Lehrer nach
            Worms gekommen war, einen Mentor, der die Pfadfinderarbeit für fast
            15 Jahre stark prägte. Er forcierte auch die Gründung neuer Gruppen
            im Raum Worms, so dass sich der Gau nach Rheinhessen und in die
            Vorderpfalz ausbreite.
          </p>
          <p>
            Gegen Ende der 60er Jahre wurden die ersten Mädchengruppen in
            unserem Gau gegründet; die CPD war jetzt ein koedukativer
            Jugendverband. Auf die Arbeit der einzelnen Gruppen hatte dies
            zunächst keinen großen Einfluss, aber im großen Maßstab schon: 1973
            wurde der Verband Christlicher Pfadfinderinnen und Pfadfinder (VCP)
            gegründet. Viel stärker wirkte sich aus, dass Alt-Burgund nach 1975
            für sechs Jahre ohne Führer war. Einige Gruppen schliefen ganz ein,
            andere schrumpften deutlich.
          </p>
          <p>
            Einen neuen Aufschwung erlebte der Gau dann zwischen 1980 und 1990.
            Zu dieser Zeit erreichte Alt-Burgund auch seine größte Ausdehnung
            mit Gruppen von Kaiserslautern bis Rimbach und von Mainz bis Bad
            Dürkheim. Die klassische Pfadfinderei wurde jetzt ergänzt durch eine
            intensive musische Arbeit, zu den Lagern und Fahrten gesellten sich
            Tanz- und Chorwochenenden.
          </p>
        </Text>
        <Pic imageWidth={240}>
        </Pic>
      </TextPic>
      <br />
      <br />
    </Layout>
  );
}

// graph queries
export const query = graphql`
  query {
    loewe: file(relativePath: { eq: "gab-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    jurte: file(relativePath: { eq: "jurte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
